var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "city-select",
      attrs: {
        title: _vm.title,
        visible: _vm.value,
        width: "750px",
        loading: !_vm.provincesList.length,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: !_vm.provincesList.length,
              expression: "!provincesList.length",
            },
          ],
          staticStyle: { "min-height": "300px" },
        },
        _vm._l(_vm.provincesList, function (item) {
          return _c(
            "div",
            { key: item.regionCode, staticClass: "box-item" },
            [
              item.nodes.length
                ? _c(
                    "el-popover",
                    {
                      attrs: { placement: "bottom", trigger: "click" },
                      on: {
                        hide: function ($event) {
                          item.show = false
                        },
                        show: function ($event) {
                          item.show = true
                        },
                      },
                    },
                    [
                      _c("CityPopoverList", {
                        attrs: { cityList: _vm.cityList, data: item.nodes },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "flex flex-m",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("el-checkbox", {
                            attrs: { indeterminate: item.indeterminate },
                            on: {
                              change: function ($event) {
                                return _vm.changeProvince(item)
                              },
                            },
                            model: {
                              value: item.checked,
                              callback: function ($$v) {
                                _vm.$set(item, "checked", $$v)
                              },
                              expression: "item.checked",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _c("span", [_vm._v(_vm._s(item.regionName))]),
                          ]),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !item.show,
                                expression: "!item.show",
                              },
                            ],
                            staticClass: "el-icon-arrow-down",
                          }),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.show,
                                expression: "item.show",
                              },
                            ],
                            staticClass: "el-icon-arrow-up",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "flex flex-m" },
                    [
                      _c("el-checkbox", {
                        attrs: { indeterminate: item.indeterminate },
                        on: {
                          change: function ($event) {
                            return _vm.changeProvince(item)
                          },
                        },
                        model: {
                          value: item.checked,
                          callback: function ($$v) {
                            _vm.$set(item, "checked", $$v)
                          },
                          expression: "item.checked",
                        },
                      }),
                      _c("div", { staticClass: "name" }, [
                        _c("span", [_vm._v(_vm._s(item.regionName))]),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer flex",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.allChange },
              model: {
                value: _vm.checkAll,
                callback: function ($$v) {
                  _vm.checkAll = $$v
                },
                expression: "checkAll",
              },
            },
            [_vm._v("全选")]
          ),
          _c(
            "div",
            { staticClass: "flex-1 fr" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.isEmpty,
                    type: "primary",
                  },
                  on: { click: _vm.confirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }