var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addr-list flex" },
    _vm._l(_vm.menuList, function (item, index) {
      return _c(
        "el-scrollbar",
        { key: index, staticClass: "a-outer" },
        _vm._l(item, function (row) {
          return _c(
            "div",
            {
              key: row.regionCode,
              staticClass: "item",
              class: { weight: row.indeterminate },
              attrs: { tabindex: "-1" },
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-m" },
                [
                  _c("el-checkbox", {
                    attrs: { indeterminate: row.indeterminate },
                    on: {
                      change: function ($event) {
                        return _vm.changeItem($event, row)
                      },
                    },
                    model: {
                      value: row.checked,
                      callback: function ($$v) {
                        _vm.$set(row, "checked", $$v)
                      },
                      expression: "row.checked",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "name",
                      on: {
                        click: function ($event) {
                          return _vm.clickCity(row)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(row.regionName) + " ")]
                  ),
                  row.loading
                    ? _c("i", { staticClass: "el-icon-loading" })
                    : row.type < 3 &&
                      (row.nodes === null || row.nodes.length > 0)
                    ? _c("i", {
                        staticClass: "el-icon-arrow-right",
                        on: {
                          click: function ($event) {
                            return _vm.clickCity(row)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        0
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }