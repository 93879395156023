<template>
  <div class="addr-list flex">
    <el-scrollbar
      class="a-outer"
      v-for="(item, index) in menuList"
      :key="index"
    >
      <div
        class="item"
        tabindex="-1"
        :class="{ weight: row.indeterminate }"
        v-for="row in item"
        :key="row.regionCode"
      >
        <div class="flex flex-m">
          <el-checkbox
            :indeterminate="row.indeterminate"
            @change="changeItem($event, row)"
            v-model="row.checked"
          ></el-checkbox>
          <div @click="clickCity(row)" class="name">
            {{ row.regionName }}
          </div>
          <i v-if="row.loading" class="el-icon-loading"></i>
          <i
            v-else-if="
              row.type < 3 && (row.nodes === null || row.nodes.length > 0)
            "
            @click="clickCity(row)"
            class="el-icon-arrow-right"
          ></i>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "CityPopoverList",
  props: {
    data: {
      default: function () {
        return [];
      },
    },
    cityList: {
      type: Array,
      default: function () {
        return [];
      },
    }
  },
  data() {
    return {
      menuList: [],
    };
  },
  mounted() {
    this.menuList = [this.data];
  },
  methods: {
    checkSelect(item) {
      
      if (!item.parentNode) {
        return;
      };
      let brother = item.parentNode.nodes;
      let checkArr = brother.filter((e) => e.checked);
      let indeterminate = brother.filter((e) => e.indeterminate);

      if (checkArr.length === brother.length) {
        item.parentNode.checked = true;
        item.parentNode.indeterminate = false;
      } else if (!checkArr.length && !indeterminate.length) {
        item.parentNode.checked = false;
        item.parentNode.indeterminate = false;
      } else {
        item.parentNode.checked = false;
        item.parentNode.indeterminate = true;
      }

      this.checkSelect(item.parentNode);
    },
    checkSelectBack(arr, val) {
      arr.forEach((e) => {
        e.checked = val;
        if (e.nodes && e.nodes.length) {
          this.checkSelectBack(e.nodes, val);
        }
      });
    },
    changeItem(val, item) {
      item.indeterminate && (item.indeterminate = false);
      this.checkSelect(item);
      if(item.nodes) {
        this.checkSelectBack(item.nodes, item.checked);
      }
      
      this.$emit("areaChange");
    },
    resetPopovers() {
      data.forEach((e) => {
        if (e.showNodes) e.showNodes = false;

        e.nodes &&
          e.nodes.forEach((se) => {
            if (se.showNodes) se.showNodes = false;
            se.nodes &&
              se.nodes.forEach((sse) => {
                if (sse.showNodes) sse.showNodes = false;
              });
          });
      });
    },
    async clickCity(item) {
      if (item.type == 3) return;

      this.menuList = this.menuList.splice(0, Number(item.type));

      if (item.type == 2) {
        if (!item.nodes) {
          item.loading = true;
          let moreCity = await this.getCityByCode(item.regionCode);
          item.loading = false;
          if (moreCity.code === 0 && moreCity.data) {
            moreCity.data.forEach((e) => {
              if(item.checked) {
                e.checked = true;
              } else {
                if(this.cityList.length) {
                  let checkIten = this.cityList.find(se => se.regionCode == e.regionCode);
                  if(checkIten) {
                    e.checked = true;
                  } else {
                    e.checked = false;
                  }
                } else {
                  e.checked = false;
                }
              }
              
              e.pname = item.pname + e.regionName;
              e.parentNode = item;
              e.nodes = null;
            });
            item.nodes = moreCity.data;
          }
        }
      }

      item.nodes && item.nodes.length && this.menuList.push(item.nodes);
    },
    getCityByCode(code) {
      return this.$axios.get(
        `${this.$api.commonNew.getChildRegion}?regionCode=${code}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.addr-list {
  height: 320px;
  .a-outer {
    height: 100%;
    border-right: 1px solid #e4e7ed;
    &:last-child {
      border-color: transparent;
    }
  }
  .item {
    padding: 0 10px;
    &.weight {
      color: #1890ff;
    }

    &:focus {
      background: #f5f7fa;
    }
    &:hover {
      background: #f5f7fa;
    }
    .name {
      width: 130px;
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 36px;
      text-overflow: ellipsis;
      cursor: pointer;
      position: relative;
      top: 1px;
    }
    .el-icon-arrow-right {
      margin-left: 3px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
</style>

<style scoped>
.a-outer >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>