<template>
  <el-dialog
    class="city-select"
    :title="title"
    :visible="value"
    width="750px"
    :loading="!provincesList.length"
    :before-close="handleClose"
  >
    <div v-loading="!provincesList.length" style="min-height: 300px">
      <div
        class="box-item"
        v-for="item in provincesList"
        :key="item.regionCode"
      >
        <el-popover
          @hide="item.show = false"
          @show="item.show = true"
          v-if="item.nodes.length"
          placement="bottom"
          trigger="click"
        >
          <CityPopoverList
            :cityList="cityList"
            :data="item.nodes"
          ></CityPopoverList>
          <div class="flex flex-m" slot="reference">
            <el-checkbox
              :indeterminate="item.indeterminate"
              @change="changeProvince(item)"
              v-model="item.checked"
            ></el-checkbox>
            <div class="name">
              <span>{{ item.regionName }}</span>
            </div>
            <i class="el-icon-arrow-down" v-show="!item.show"></i>
            <i class="el-icon-arrow-up" v-show="item.show"></i>
          </div>
        </el-popover>
        <div class="flex flex-m" v-else>
          <el-checkbox
            :indeterminate="item.indeterminate"
            @change="changeProvince(item)"
            v-model="item.checked"
          ></el-checkbox>
          <div class="name">
            <span>{{ item.regionName }}</span>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer flex">
      <el-checkbox @change="allChange" v-model="checkAll">全选</el-checkbox>
      <div class="flex-1 fr">
        <el-button
          size="mini"
          :disabled="isEmpty"
          type="primary"
          @click="confirm"
          >确定</el-button
        >
      </div>
    </span>
  </el-dialog>
</template>

<script>
import CityPopoverList from "./CityPopoverList.vue";
export default {
  components: {
    CityPopoverList,
  },
  props: {
    value: {
      default: false,
    },
    title: {
      default: "选择城市",
    },
    cityList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      checkAll: false,
      provincesList: [],
    };
  },
  computed: {
    isEmpty() {
      const checkTrue = function (list) {
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          if (item.checked || item.indeterminate) {
            return true;
          }
        }
      };

      let hasCheck = checkTrue(this.provincesList);

      return !hasCheck;
    },
  },
  watch: {
    cityList() {
      this.initChecked();
    },
  },
  mounted() {
    this.getCityList();
  },
  methods: {
    checkIsAll() {
      let isCheck = this.provincesList.every((e) => e.checked);
      if (isCheck) {
        //全选
          this.checkAll = true;
      } else {
          this.checkAll = false;
      }
    },
    changeProvince(item) {
      if (item.checked) {
        item.indeterminate = false;
      }
      this.toggleAllCity(item.nodes, item.checked);
      this.checkIsAll();
    },
    toggleAllCity(arr, val) {
      arr.forEach((e) => {
        e.checked = val;
        e.indeterminate = false;
        if (e.nodes && e.nodes.length) {
          this.toggleAllCity(e.nodes, val);
        }
      });
    },
    allChange(val) {
      if (val) {
        this.toggleAllCity(this.provincesList, true);
      } else {
        this.toggleAllCity(this.provincesList, false);
      }
    },
    checkSelect(item) {
      if (!item.parentNode) {
        return;
      }
      let brother = item.parentNode.nodes;
      let checkArr = brother.filter((e) => e.checked);
      let indeterminate = brother.filter((e) => e.indeterminate);

      if (checkArr.length === brother.length) {
        item.parentNode.checked = true;
        item.parentNode.indeterminate = false;
      } else if (!checkArr.length && !indeterminate.length) {
        item.parentNode.checked = false;
        item.parentNode.indeterminate = false;
      } else {
        item.parentNode.checked = false;
        item.parentNode.indeterminate = true;
      }

      this.checkSelect(item.parentNode);
    },
    checkSelectBack(arr, val) {
      arr.forEach((e) => {
        e.checked = val;
        if (e.nodes && e.nodes.length) {
          this.checkSelectBack(e.nodes, val);
        }
      });
    },
    matchSelectCity(arr, idDic, idArr) {
      arr.forEach((e) => {
        if (e.type == 2 && !e.nodes) {
          if (idDic[e.regionCode]) {
            e.checked = true;
            this.checkSelect(e);
          } else {
            let hasChild = idArr.find(
              (item) =>
                item.regionCode.length > 6 &&
                item.regionCode.includes(e.regionCode)
            );

            if (hasChild) {
              e.indeterminate = true;
              this.checkSelect(e);
            }
          }
        } else {
          if (idDic[e.regionCode]) {
            e.checked = true;
            this.checkSelect(e);
            if (e.nodes) {
              this.checkSelectBack(e.nodes, e.checked);
            }
          } else {
            if (e.nodes && e.nodes.length) {
              this.matchSelectCity(e.nodes, idDic, idArr);
            }
          }
        }
      });
    },
    initChecked() {
      this.toggleAllCity(this.provincesList, false);
      if (!this.cityList.length || !this.provincesList.length) {
        this.checkIsAll();
        return
      };

      let idDic = {};
      this.cityList.forEach((e) => (idDic[e.regionCode] = true));

      this.matchSelectCity(this.provincesList, idDic, this.cityList);
      this.checkIsAll();
    },
    handleClose(done) {
      this.$emit("input", false);
      done && done();
    },
    //转换城市格式
    formatCitys(data = {}) {
      let { provinces, citys, countrys } = data;
      provinces = JSON.parse(provinces || "[]");
      citys = JSON.parse(citys || "[]");
      countrys = JSON.parse(countrys || "[]");

      provinces.forEach((e) => {
        e.checked = false;
        e.show = false;
        e.indeterminate = false;
        e.pname = e.regionName;
        e.parentNode = null;
        e.nodes = citys.filter((city) => {
          if (city.pid === e.regionCode) {
            city.checked = false;
            city.indeterminate = false;
            city.pname = e.pname + city.regionName;
            city.parentNode = e;
            city.nodes = countrys.filter((country) => {
              if (country.pid === city.regionCode) {
                country.checked = false;
                country.indeterminate = false;
                country.pname = city.pname + country.regionName;
                country.parentNode = city;
                country.nodes = null;
                country.loading = false;
                return true;
              }
            });
            return true;
          }
        });
      });

      return provinces;
    },
    findCheckedObj(obj) {
      return [
        {
          [obj.regionCode]: [],
        },
        {
          regionName: obj.regionName,
          regionCode: obj.regionCode,
          fullName: obj.pname,
        },
      ];
    },
    //确定的时候格式化街道数

    formatArea(allNodes, nodeList = []) {
      let data;
      if (allNodes[0] && allNodes[0].type == 0) {
        data = {};
      } else {
        data = [];
      }

      allNodes.forEach((e) => {
        if (e.checked) {
          if (e.type == 0) {
            data[e.regionCode] = [];
          } else {
            data.push({
              [e.regionCode]: [],
            });
          }

          nodeList.push({
            regionName: e.regionName,
            regionCode: e.regionCode,
            fullName: e.pname,
          });
        }

        if (e.indeterminate && e.nodes) {
          if (e.type == 0) {
            data[e.regionCode] = this.formatArea(e.nodes, nodeList);
          } else {
            data.push({
              [e.regionCode]: this.formatArea(e.nodes, nodeList),
            });
          }
        }

        if (e.indeterminate && !e.nodes) {
          let finalArr = this.cityList.filter(
            (se) =>
              se.regionCode.length > 6 && se.regionCode.includes(e.regionCode)
          );

          data.push({
            [e.regionCode]: finalArr.map((sse) => {
              return { [sse.regionCode]: [] };
            }),
          });

          nodeList.push.apply(nodeList, finalArr);
        }
      });
      return data;
    },
    confirm() {
      let fullList = [];
      let res = this.formatArea(this.provincesList, fullList);
      console.log("res", res, fullList);
      this.$emit("confirm", { list: res, fullList: fullList });
      this.handleClose();
    },
    getCityList() {
      this.$axios.get(this.$api.commonNew.getAllRegion).then((res) => {
        if (res.code === 0) {
          this.provincesList = this.formatCitys(res.data);
          this.initChecked();
          // console.log(111, this.provincesList);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.city-select {
  .box-item {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    cursor: pointer;
    margin-bottom: 25px;
    .name {
      margin-left: 5px;
      .select-amount {
        color: #1890ff;
        font-weight: bold;
        font-size: 12px;
      }
    }
    .el-icon-arrow-down {
      margin-left: 3px;
      font-weight: bold;
    }
  }
  .dialog-footer {
    border-top: 1px solid #dcdfe6;
    padding-top: 15px;
    .select-text {
      margin-right: 15px;
    }
  }
}
</style>